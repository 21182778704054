import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2997050974/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { FormProps } from '../../components/FormProps';
export const name = 'UserForm';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  name,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{name}</h2>
    <FormProps fields={[{
      name: 'username',
      type: 'text',
      description: 'This field should not be mutable'
    }, {
      name: 'email',
      type: 'text'
    }, {
      name: 'displayName',
      type: 'text'
    }, {
      name: 'enabled',
      type: 'checkbox'
    }, {
      name: 'spaceAdmin',
      type: 'checkbox'
    }, {
      name: 'password',
      type: 'password'
    }, {
      name: 'passwordConfirmation',
      type: 'password'
    }, {
      name: 'changePassword',
      type: 'checkbox'
    }, {
      name: 'allowedIps',
      type: 'text'
    }, {
      name: 'preferredLocale',
      type: 'select'
    }, {
      name: 'timezone',
      type: 'select'
    }, {
      name: 'attributes',
      type: 'attributes'
    }, {
      name: 'profileAttributes',
      type: 'attributes'
    }, {
      name: 'memberships',
      type: 'team-multi'
    }]} formOptions={[{
      name: 'username',
      type: 'string',
      description: 'When provided the form will be populated by retrieving the corresponding user and will update that user when submitted. If not provided the form submission will create a new user.'
    }]} dataSources={[{
      name: 'attributeDefinitions',
      type: 'AttributeDefinition[]'
    }, {
      name: 'locales',
      type: 'Locale[]'
    }, {
      name: 'profileAttributeDefinitions',
      type: 'AttributeDefinition[]'
    }, {
      name: 'timezones',
      type: 'Timezone[]'
    }, {
      name: 'user',
      type: 'User'
    }]} mdxType="FormProps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      